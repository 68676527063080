import React, { useState } from 'react';
import { Button } from '@mui/material';
import './LoginPage.css'; // スタイル用のCSSファイルを作成する
import ProgressSteps from '../../componets/ProgressSteps.jsx';
import { useNavigate } from 'react-router-dom';


//画像のインポート
import backgroundImage from '../../assets/backgroun-image.png';
import sendaiImage from '../../assets/sendai.png';

function CompletePage(){
  const navigate = useNavigate();
  const navigateToSignUpScreen = () => {
    navigate('/');
  };

  return (
    <div className="login-page">
      <img src={backgroundImage} alt="Background" className="background-image" />
      <img src={sendaiImage} alt="Sendai" className="logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={3}/>
      <div className="login-container">
        <div className="form-container">
        <div style={{ textAlign: 'center', width: '100%' }}>
            <label style={{ display: 'block', marginTop: '50px' }}>アカウント登録が完了しました</label>
            <br/>
            <label style={{ display: 'block', marginTop: '10px', marginBottom: '50px' }}>「ログイン」ボタンをクリックして<br/>サービスをご利用ください</label>
        </div>
        <Button className="loginButton" onClick={navigateToSignUpScreen}>ログイン</Button>
        </div>
      </div>
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default CompletePage;