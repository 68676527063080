import React, { useState, useEffect } from 'react';
import './AccountPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles } from '../../componets/TextField.jsx'
import FormHelperText from '@mui/material/FormHelperText';
import {isValidPassword, isConfirmPassword} from '../../utils/validation.js'
import { useNavigate } from 'react-router-dom';

function PasswordEditPage(){

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordCheckError, setPasswordCheckError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  const passEdit = async () => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            password: password,
            new_password: newPassword,
            pattern:'edit_password'
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          setIsComplete(true);
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }

  useEffect(() => {
    if(newPasswordCheck.length > 0){
      if (passwordError || passwordCheckError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
    console.log(passwordError, passwordCheckError, isButtonDisabled)
  }, [passwordError, passwordCheckError]);

  //入力されたパスワードのチェック
  useEffect(() => {
    if(newPassword.length > 0){
      setPasswordError(!isValidPassword(newPassword));
    }
  }, [newPassword]);
  //入力されたパスワード確認の情報のチェック
  useEffect(() => {
    if(newPasswordCheck.length > 0){
      setPasswordCheckError(!isConfirmPassword(newPasswordCheck, password));
    }
  }, [newPasswordCheck, newPassword]);
  
    return (
        <div className='account-page'>
          <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
            <label style={{fontSize: '14px', marginLeft:'8%'}}>パスワード変更</label>
          </div>
          {!isComplete && (
            <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
              <label>変更するパスワードを入力してください</label>
            </div>
          )}
          {isComplete && (
            <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
              <label>パスワードの変更が完了しました。</label>
            </div>
          )}
          {!isComplete && (
            <div className='list-container-password'>
              <label htmlFor="password" style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>現在のパスワード</label>
              <TextField
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  slotProps={{
                  input: {
                      sx: inputStyles
                  },
                  }}
              />
              <label htmlFor="password" style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>新しいパスワード</label>
              <TextField
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  error={passwordError}
                  slotProps={{
                  input: {
                      sx: inputStyles
                  },
                  }}
              />
              {passwordError && (
                  <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                      10文字以上で、大文字、小文字、数字、記号
                  </FormHelperText>
                  )}
              <label htmlFor="passwordCheck" style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>新しいパスワード再入力（確認用）</label>
              <TextField
                  type="password"
                  id="passwordCheck"
                  value={newPasswordCheck}
                  onChange={(e) => setNewPasswordCheck(e.target.value)}
                  fullWidth
                  error={passwordCheckError}
                  helperText={passwordCheckError ? 'パスワードが一致しません。' : ''}
                  slotProps={{
                  input: {
                      sx: inputStyles
                  },
                  }}
                  style={{marginBottom:'30px'}}
              />
            </div>
          )}
          {!isComplete && (
            <Button className="loginButton-change" onClick={passEdit}>変更する</Button>
          )}
          {!isComplete && (
            <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '50px'}}>戻る</Button>
          )}
          {isComplete && (
            <Button className="loginButton-back" onClick={navigateToAccount} style={{marginTop: '100px'}}>戻る</Button>
          )}
          {isLoading && (
            <div className="loading-overlay">
              <h2>パスワードを変更中です。しばらくお待ちください。</h2>
              <div className="loader"></div>
            </div>
          )}
          <footer>
                <p>Copyright©City of Sendai All Rights Reserved.</p>
          </footer>
        </div>
      );
};

export default PasswordEditPage;