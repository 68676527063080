import React, { useState } from 'react';
import './AccountPage.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function DeletePage(){

  const deleteButton = async () => {
    try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            pattern:'delete_user'
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('User registration successful', responseData);
          navigateToError();
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      }
  }

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };
  const navigateToError = () => {
    navigate('/error', { replace: true });
  };
  
  return (
    <div className='account-page'>
      <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
        <label style={{fontSize: '14px', marginLeft:'8%'}}>アカウント削除</label>
      </div>
      <div className='list-container-text' style={{ justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'14px', paddingTop:'20px', paddingBottom: '30px'}}>
         <label style={{fontSize: '16px', fontWeight: '700', marginTop:'10px', marginBottom:'20px', display: 'block'}}>アカウントを削除されます</label>
         <p>アカウントを削除すると、マイページ内の情報（メールアドレス、ニックネーム、パスワード、連携データ）が完全に削除されます。</p>
         <p>「削除する」ボタンを押すと、操作を取り消すことはできません。</p>
         <label style={{fontSize: '16px', fontWeight: '700', marginTop:'55px', display: 'block'}}>一時的に連携を解除するには</label>
         <p>アカウント情報変更画面の「ログアウト」を選択すると、一時的にマイページからアカウントを切り離すことができます。</p>
      </div>
      <Button className="loginButton-delete-back" onClick={deleteButton}>アカウント削除</Button>
      <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '50px'}}>戻る</Button>
      <footer>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default DeletePage;