import { generateCodeVerifier, generateCodeChallenge } from './PKCEService.js';

//SENDAIポータルのログイン
export const providerSignIn = (provider) => {

    //コードチャレンジ作成
    const verifier = generateCodeVerifier();
    const challenge = generateCodeChallenge(verifier);

    // セッションストレージに保存
    sessionStorage.setItem('code_verifier', verifier);

    const clientId = 'l5bh1ba8ctbooblv82u6d3922';
    const responseType = 'code';
    const scope = 'openid profile email aws.cognito.signin.user.admin';
    const redirectUri = 'https://dev-login.sendai-portal.jp/';
    const identityProvider = provider;
    const state = 'H4sIAAAAAAAAAFWRXW-bMBSG_4uvA8UQPi9JaRPakiVpRbJpQsfYgFewCQbSZdp_n9NpF7s6j_zKr46e8wsBihD0hpDD2DBQo4EL6_HhvElwfUULRHR8gkZKzaXm1iUNJhCUI5GStHNgTx51QtvWOdV5KTuTfUDXt8xUTFDgM2eX6O5O5-wzp0xjpXG7uV9prFH0DcFFmaWsBR-lqbiewpwUG0ygHRe3rx3wVk_ZM8Gphr6R4lbUD7LiLUPfF-hddwb2QSXJc5rlKSbOvHOgXBH5tU_zTWVdtj3Gufsz2cMh2K_p04vtr2f27NMdL7oW95Bma9fI0nC0VqfgmuCUq93HWYXx_BJ3_RKmSzw1p7F4bZogeFu-x1-KbF8cyzpQWYJLdxADr2oOx-DHXmFavz7N1fmQe2v3YX6sjvmOzmK6LxuyjZVevtUb_5Vk9No_tAZMY2NQNps3MP-_iwkdXKX4p0mb1hUdirDvYHfpY8fROlBUQavYAg03Gx4JwLcsw6oqbCyJbRkEPNsIw4pazPI8_YR-_wH87Jv8BAIAAA.H4sIAAAAAAAAAAEgAN__qHSEsZSRQKGU6rQLoVcLsCfrxMdQLTksE7Q2jp6dBZFluXT-IAAAAA.3';//クライアント側が指定
    const nonce = 'idtokendesuyonekorewa';
    // const codeChallenge = challenge;
    // const codeChallengeMethod = 'S256';

    const authUrl = `https://sendai-portal-auth-dev.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?` +
      `client_id=${clientId}&` +
      `response_type=${responseType}&` +
      `scope=${encodeURIComponent(scope)}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `identity_provider=${identityProvider}&` +
      `state=${state}&` +
      `nonce=${nonce}&`
      // `code_challenge=${codeChallenge}&` +
      // `code_challenge_method=${codeChallengeMethod}`;

    //Auth0のログイン画面を表示
    window.location.href = authUrl;
 };