import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const RootComponent = () => {
  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //<React.StrictMode>
    <RootComponent />
  //</React.StrictMode>
);

reportWebVitals();


///
///
///</React.StrictMode>
///上記は本番モードの時にコメントアウトを戻すこと！！！！
///
///