import React, { useState, useEffect } from 'react';
import './AccountPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles } from '../../componets/TextField.jsx'
import { useNavigate } from 'react-router-dom';

function MailEditPage(){

  const [mail, setMail] = useState('');
  const [newMail, setNewMail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  const mailEdit = async () => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            mail: mail,
            new_mail: newMail,
            pattern:'edit_mail'
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('User registration successful', responseData);
          setIsComplete(true);
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            pattern:'user_info_email'
          }),
          credentials: 'include'
        });
        const data = await response.json();
        setUserInfo(data.Response);
        console.log(data);
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      setMail(userInfo.email);
    }
  }, [userInfo]);
  
    return (
        <div>
          {userInfo && (
            <div className='account-page'>
              <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
                <label style={{fontSize: '14px', marginLeft:'8%'}}>メールアドレス変更</label>
              </div>
              {!isComplete && (
                <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
                  <label>変更するメールアドレスを入力してください</label>
                </div>
              )}
              {isComplete && (
                <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
                  <label>メールアドレスの変更が完了しました。</label>
                </div>
              )}
              {!isComplete && (
                <div className='list-container-editmail'>
                  <label htmlFor="email" 
                    style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                      現在のメールアドレス
                  </label>
                  <TextField
                    type="email"
                    id="email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="例：current_mail@sample.com"
                    fullWidth
                    error={emailError}
                    slotProps={{
                      input: {
                        sx: inputStyles
                      },
                    }}
                  />
                  <label htmlFor="email" 
                    style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                      新しいメールアドレス
                  </label>
                  <TextField
                    type="email"
                    id="email2"
                    value={newMail}
                    onChange={(e) => setNewMail(e.target.value)}
                    placeholder="例：new_mail@sample.com"
                    fullWidth
                    error={newEmailError}
                    slotProps={{
                      input: {
                        sx: inputStyles
                      },
                    }}
                    style={{marginBottom:'30px'}}
                  />
                </div>
              )}
              {!isComplete && (
                <Button className="loginButton-change" onClick={mailEdit}>変更する</Button>
              )}
              {!isComplete && (
                <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '50px'}}>戻る</Button>
              )}
              {isComplete && (
                <Button className="loginButton-back" onClick={navigateToAccount} style={{marginTop: '100px'}}>戻る</Button>
              )}
              {isLoading && (
                <div className="loading-overlay">
                  <h2>メールアドレスを変更中です。しばらくお待ちください。</h2>
                  <div className="loader"></div>
                </div>
              )}
              <footer>
                    <p>Copyright©City of Sendai All Rights Reserved.</p>
              </footer>
            </div>
          )}
        </div>
      
    );
};

export default MailEditPage;