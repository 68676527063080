import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { SvgIcon, Box } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { useMediaQuery } from '@mui/material';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7.5,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#477b5c',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#477b5c',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: 'rgba(200,200,200,1)',
    borderRadius: 1,
  },
  
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 0,
  color: '#fff',
  width: 15,
  height: 15,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative', 
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: '#477b5c',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: '#477b5c',
      },
    },
  ],
}));

const WhiteFilledCircle = styled(SvgIcon)(({ theme }) => ({
  width: 25,
  height: 25,
  borderRadius: '50%',
  backgroundColor: 'rgba(200,200,200,1)',
}));

//アクティブマーク
const ActiveFilledCircle = styled(SvgIcon)(({ theme }) => ({
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#477b5c',
  }));
  const ActiveMark = styled(SvgIcon)(({ theme }) => ({
    position: 'absolute',
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: 'white',
  }));


//チェックマーク
const CheckFilledCircle = styled(SvgIcon)(({ theme }) => ({
  width: 25,
  height: 25,
  borderRadius: '50%',
  backgroundColor: '#477b5c',
}));
const CheckMark = styled(Check)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px', // チェックマークのサイズを調整
    color: 'white', // チェックマークの色
    stroke: 'white', // 色
  strokeWidth: '2px', 
    zIndex: 2,
  }));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <CheckFilledCircle />: active ? <ActiveFilledCircle /> : <WhiteFilledCircle />}
      {completed ? <CheckMark /> : active && <ActiveMark/>} 
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const steps = [
  // {id: 1, label: <span style={{ fontSize: '10.5px' }}>STEP1<br />アドレス入力</span>},
  // {id: 2, label:<span style={{ fontSize: '10.5px' }}>STEP2<br />確認コード発行</span>},
  // {id: 3, label: <span style={{ fontSize: '10.5px' }}>STEP3<br />本登録完了</span>}
  <span style={{ fontSize: '10.5px' }}>STEP1<br />アドレス入力</span>,
  <span style={{ fontSize: '10.5px' }}>STEP2<br />確認コード発行</span>,
  <span style={{ fontSize: '10.5px' }}>STEP3<br />本登録完了</span>
];

export default function ProgressSteps({activeStep}) {

  const isWideScreen = useMediaQuery('(min-width: 680px)');

  return (
    <Box
      sx={{
        width: '85%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '8px',
        padding: '20px 0px 10px 0px',
        marginTop: '0px',
        marginBottom: '20px',
        ...(isWideScreen && {
          marginTop: '10px',
          marginBottom: '40px',
          padding: '30px 0px 25px 0px',
          width: '28%',
          minWidth: '500px',
        }),
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} sx={{ width: '100%' }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
}

ProgressSteps.propTypes = {
  activeStep: PropTypes.number.isRequired,
};