//テキストフィールドのデザイン
export const inputStylesMail = {
    backgroundColor: 'lightgray', // 背景色をグレーに設定
    '&.Mui-disabled': {
      color: 'black',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      boxShadow: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #477b5c',
    },
    '& input': {
      caretColor: '#477b5c', // カーソルの色を設定
      margin: '9.5px 5px',
      padding: '10px',
      borderRadius: '5px',
      border: 'none',
      width: '100%',
      boxSizing: 'border-box',
      fontSize: '16px',
      backgroundColor: 'transparent',
    },
  };
  
  //テキストフィールドのデザイン
  export const inputStyles = {
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      boxShadow: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #477b5c',
    },
    '& input': {
      caretColor: '#477b5c', // カーソルの色を設定
      margin: '9.5px 5px',
      padding: '10px',
      borderRadius: '5px',
      border: 'none',
      width: '100%',
      boxSizing: 'border-box',
      fontSize: '16px',
      backgroundColor: 'transparent',
    },
  };