import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import './LoginPage.css';
import OptoinPage from '../../OptoinPage.jsx';

//プロバイダーサインインメソッド呼び出し
import { providerSignIn } from '../../service/SocialLoginService';

//Auth0サインインメソッド呼び出し
import { sendaiPortalLogin } from '../../service/Auth0Login';

//オプトインの確認
import { checkOptoin } from '../../service/OptinService.js';

//クライアントによるリクエストログイン（アプリに戻すためのログイン）
import { requestLogin } from '../../service/RequestLogin.js';

//画像のインポート
import backgroundImage from '../../assets/backgroun-image.png';
import sendaiImage from '../../assets/sendai.png';
import googleImage from '../../assets/google.png';
import yahooImage from '../../assets/yahoo.png';
import appleImage from '../../assets/apple.png';
import sendaiportalImage from '../../assets/sendai-app.png';

function LoginPage(){
  const keysToCheck = ['client_id', 'redirect_uri', 'scope', 'response_type'];
  //ローディング画面の表示可否フラグ
  const [isLoading, setIsLoading] = useState(false);
  const [isOptin, setIsOptin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToMyPageScreen = () => {
    navigate('/account');
  };

  const navigateToOptinPage = (userInfo_flag) => {
    navigate('/optin' , { state: { userInfo_flag: userInfo_flag } } );
  };

  //SENDAIポータルログインメソッド呼び出し
  function sendaiPortalLoginButton(){
    setIsLoading(true);
    sessionStorage.setItem('provider', 'sendai-portal');
    sendaiPortalLogin();
    setIsLoading(false);
  }


  //Googleログインメソッド呼び出し
  function GoogleLoginButton(){
    setIsLoading(true);
    sessionStorage.setItem('provider', 'Google');
    providerSignIn('Google');
    setIsLoading(false);
  }

  //Appleログインメソッド呼び出し
  function AppleLoginButton(){
    setIsLoading(true);
    sessionStorage.setItem('provider', 'SignInWithApple');
    providerSignIn('SignInWithApple');
    setIsLoading(false);
  }

  //画面作成時に実行される
  useEffect(() => {
    const fetchToken = async () => {

      //URLを取得するための初期化
      const params = new URLSearchParams(location.search);
      //認可code取得
      const codeParam = params.get('code');
      // チェックするクエリパラメータが含まれているか確認（'client_id', 'redirect_uri', 'scope', 'response_type'）
      const checkQuery = keysToCheck.some(key => params.has(key));

      if (codeParam){  //認可コードがあれば以下実行
        //ロード画面スタート
        setIsLoading(true);

        // // クエリパラメータを削除
        // // すべてのクエリパラメータを削除
        // params.forEach((value, key) => {
        //   params.delete(key);
        // });

        // // クエリパラメータを削除したURLにナビゲート
        // navigate({ search: '' }, { replace: true });

        // 認可コードからオプトイン判定
        try {
          //オプトインチェック
          const response = await checkOptoin(codeParam);
  
          //オプトインのリクエストに成功した場合
          if (response.ok) {
            const responseData = await response.json();
            console.log(responseData);
            // customOptinがnullまたは'0'の場合の処理（未設定の場合）
            if (responseData.customOptin === 'null' || responseData.customOptin === '0') {
              //オプトイン画面の表示
              navigateToOptinPage(responseData.infoFlag);
            } else {
              if(responseData.infoFlag){
                //二度目のログイン（アプリに戻すためのログイン）
                requestLogin();
              }else if(!responseData.infoFlag){
                navigate('/regist');
              }
            }
          } else {
            const errorData = await response.json();
            console.error('Failed to register user:', errorData);
          }
        } catch (error) {
          console.error('Error during registration:', error);
        }

        //ロード画面の終了
        setIsLoading(false);

      }else if(checkQuery){
        // すべてのクエリパラメータを文字列に変換
        const queryString = params.toString();
        // セッションストレージに認可エンドポイントのクエリ情報を保存
        sessionStorage.setItem('queryString', queryString);
      }
    };

    //userEfect内で非同期処理を実行
    fetchToken();

  }, [location]);

  // const [isForeground, setIsForeground] = useState(true);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     const isPageVisible = !document.hidden;
  //     setIsForeground(isPageVisible);
  //     console.log(`ブラウザは${isPageVisible ? '最前面' : 'バックグラウンド'}にあります。`);
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  return (
    <div>
      <div className="login-page">
        <img src={backgroundImage} alt="Background" className="background-image" />
        <img src={sendaiImage} alt="Sendai" className="logo" />
          <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <div className="login-container">
          <div className="social-login">
            <div className="form-container">
            <Button className="loginButton-sendai" onClick={sendaiPortalLoginButton} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={sendaiportalImage} alt="Sendai" style={{ marginRight: '15px', width: '35px', height: '35px', padding: '5px' }} />
              SENDAIポータルでログイン
            </Button>
            <div className='account-regist'>
            <div className='info-text'>アカウントの登録はお済ですか？</div>
              <Link className='account-link' to="/signup">アカウント登録する</Link>
            </div>
            <div className="divider">
            <hr className="custom-hr"></hr>
            <div className='social'>または外部サイトのIDでログイン</div>
            <hr className="custom-hr"></hr>
          </div>
            <Button className="loginButton-social" onClick={GoogleLoginButton} style={{ display: 'flex', alignItems: 'center' , marginTop: '0px'}}>
              <img src={googleImage} alt="Sendai" style={{ marginRight: '10px', width: '35px', height: '35px', padding: '5px 8px' }} />
              Googleでログイン
            </Button>
            <Button className="loginButton-social" onClick={navigateToMyPageScreen} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={yahooImage} alt="Sendai" style={{ marginRight: '15px', width: '40px', height: '20px', padding: '12.5px 0px' }} />
              Yahooでログイン
            </Button>
            <Button className="loginButton-social" onClick={AppleLoginButton} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={appleImage} alt="Sendai" style={{ marginRight: '20px',marginLeft: '0px', width: '35px', height: '35px', padding: '5px 0px' }} />
              Appleでログイン
            </Button>
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="loading-overlay">
            <h2>ログイン中です。しばらくお待ちください。</h2>
            <div className="loader"></div>
          </div>
        )}
        <footer style={{zIndex: '-1'}}>
          <p>Copyright©City of Sendai All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default LoginPage;