import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import './LoginPage.css'; // スタイル用のCSSファイルを作成する
import { inputStylesMail } from '../../componets/TextField.jsx'
import { Link, useNavigate } from 'react-router-dom';
import ProgressSteps from '../../componets/ProgressSteps.jsx';
import axios from 'axios';


//画像のインポート
import backgroundImage from '../../assets/backgroun-image.png';
import sendaiImage from '../../assets/sendai.png';

function ConfirmPage(){
  const location = useLocation();
  const { email, id } = location.state || {};
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);

  const navigate = useNavigate();
  const navigateToCompleteScreen = () => {
    navigate('/complete');
  };
  const navigateToResendScreen = () => {
    navigate('/resendconfirm', { state: { email: email } });
  };

  useEffect(() => {
    const handlePopState = (event) => {
      navigate(window.location.pathname, { replace: true });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);


  //確認コード認証サービス
  const signUpConfirm = async () => {
      setIsLoading(true);
      try {
            const response = await fetch('https://c9l11b5md4.execute-api.ap-northeast-1.amazonaws.com/default/signUpConfirmFunction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                id: id,
            }),
          });
            //ユーザ登録コード発行成功
          if (response.status === 200) {
              const data = await response.json();
              const emailVerified = data.Response;

              console.log(data);

              if(emailVerified === true){
                navigateToCompleteScreen();
              }else{
                setErrorMessage('メール認証が完了していません。');
                setErrorCheck(true);
                console.log('メール認証できてないよ:', response);
              }
          } else {
              console.error('Failed to call Lambda function:', response.status);
              console.error('Response body:', errorMessage);
    
              // Translate the error message
              const translatedMessage = '不明なエラーが発生しました';
              setErrorMessage(translatedMessage);
              setErrorCheck(true);
              console.error('Translated error message:', translatedMessage);
          }
      } catch (error) {
        console.error('Error during sign-in:', error);
      } finally {
        setIsLoading(false);
      }
  };

  //確認コード再送サービス
  const resendConfirmCode = async (token) => {
    setIsLoading(true);
      const response = await fetch(`https://YOUR_AUTH0_DOMAIN/api/v2/jobs/verification-email`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: 'USER_ID'
        })
      });
  };

  return (
    <div className="login-page">
      <img src={backgroundImage} alt="Background" className="background-image" />
      <img src={sendaiImage} alt="Sendai" className="logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={1}/>
      <div className="login-container">
        <div className="form-container">
          {errorCheck && 
            <div className='error-massage'>
              {errorMessage}
            </div>
          }
          <div style={{ textAlign: 'start', width: '100%' }}>
              <label style={{ display: 'block', marginTop: '30px' }}>下記メールアドレスに送信されたURLからメールの認証を行ってください</label>
          </div>
          <label htmlFor="email" className="email">送信先メールアドレス</label>
          <TextField
            type="email"
            id="email"
            value={email}
            onChange={() => {}}
            fullWidth
            disabled
            slotProps={{
              input: {
                sx: inputStylesMail
              },
            }}
          />
          <Link className='passforget' onClick={resendConfirmCode}>
            認証メールを再送信する
          </Link>
          <Button className="loginButton" onClick={signUpConfirm}>次へ</Button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>認証情報の確認中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ConfirmPage;