import React, { useState } from 'react';
import './AccountPage.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//プロバイダーサインインメソッド呼び出し
import { providerSignIn } from '../../service/SocialLoginService';

function XIDAliginmentPage(){
  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  function xIDAliginmentButton(){
    sessionStorage.setItem('provider', 'xID');
    providerSignIn('xID');
  }
  
  return (
    <div className='account-page'>
      <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
        <label style={{fontSize: '14px', marginLeft:'8%'}}>マイナンバーカード連携</label>
      </div>
      <div className='list-container-text' style={{ justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'14px', paddingTop:'20px', paddingBottom: '30px'}}>
         <label style={{fontSize: '16px', fontWeight: '700', marginTop:'10px', marginBottom:'20px', display: 'block'}}>マイナンバーカード連携について</label>
         <p>個人情報を入力する必要がなくなり～。</p>
         <label style={{fontSize: '16px', fontWeight: '400', marginTop:'30px', marginBottom:'150px', display: 'block'}}>一時的に連携を解除するには</label>
      </div>
      <Button className="loginButton-aliginment" onClick={xIDAliginmentButton}>連携する</Button>
      <Button className="loginButton-editback" onClick={navigateToAccount} style={{marginBottom: '50px'}}>戻る</Button>
      <footer>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default XIDAliginmentPage;