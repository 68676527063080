import React, { useState } from 'react';
import '../AccountPage/AccountPage.css';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { inputStyles } from '../../componets/TextField.jsx'
import { useNavigate } from 'react-router-dom';

//クライアントによるリクエストログイン（アプリに戻すためのログイン）
import { requestLogin } from '../../service/RequestLogin.js';

function RegistInfoPage(){

  const [famiry_name, setFamilyName] = useState('');
  const [given_name, setGivenName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [famiryNameError, setEmailError] = useState(false);
  const [givenNameError, setNewEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/');
  };

  const mailEdit = async () => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            pattern:'regist_info',
            userAttributes: [
              { Name: "family_name", Value: famiry_name},
              { Name: "given_name", Value: given_name},
              { Name: "nickname", Value: given_name},
              { Name: "custom:phone", Value: phone_number}
            ]
          }),
          credentials: 'include'
        });
  
        if (response.ok) {
          const responseData = await response.json();
          console.log('ユーザ情報登録成功', responseData);
          requestLogin();
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData);
        }
  
      } catch (error) {
        console.error('Error during registration:', error);
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }
  
    return (
        <div className='account-page'>
          <div className='list-container-title' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', marginTop: '0px'}}>
            <label style={{fontSize: '14px', marginLeft:'8%'}}>ユーザ情報登録</label>
          </div>
          <div className='list-container-text' style={{display:'flex', justifyContent:'start', alignItems:'center', textAlign:'start', fontSize:'15px'}}>
            <label>プロフィール情報を登録してください</label>
          </div>
          <div className='list-container-registinfo'>
            <label htmlFor="email" 
                style={{display:'flex', justifyContent:'start', marginTop: '30px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                  フリガナ（姓）
              </label>
              <TextField
                type="text"
                id="famiry_name"
                value={famiry_name}
                onChange={(e) => setFamilyName(e.target.value)}
                placeholder="ヤマダ"
                fullWidth
                error={famiryNameError}
                slotProps={{
                  input: {
                    sx: inputStyles
                  },
                }}
              />
              <label htmlFor="email" 
                style={{display:'flex', justifyContent:'start', marginTop: '25px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                  フリガナ（名）
              </label>
              <TextField
                type="text"
                id="given_name"
                value={given_name}
                onChange={(e) => setGivenName(e.target.value)}
                placeholder="タロウ"
                fullWidth
                error={givenNameError}
                slotProps={{
                  input: {
                    sx: inputStyles
                  },
                }}
              />
              <label htmlFor="email" 
                style={{display:'flex', justifyContent:'start', marginTop: '25px', marginBottom:'5px', marginLeft:'5px', fontSize:'14px', color:'rgba(70, 70, 70, 1.0)'}}>
                  電話番号
              </label>
              <TextField
                type="tel"
                id="phone_number"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="xxx-xxxx-xxxx"
                fullWidth
                error={phoneNumberError}
                slotProps={{
                  input: {
                    sx: inputStyles
                  },
                }}
                style={{marginBottom:'20px'}}
              />
              <Button className="loginButton" onClick={mailEdit} style={{marginBottom: '30px'}}>登録する</Button>
            </div>
          {isLoading && (
            <div className="loading-overlay">
              <h2>ユーザー情報を登録中です。しばらくお待ちください。</h2>
              <div className="loader"></div>
            </div>
          )}
          <footer style={{zIndex: '-1'}}>
                <p>Copyright©City of Sendai All Rights Reserved.</p>
          </footer>
        </div>
      
    );
};

export default RegistInfoPage;