import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

//トグルボタンスイッチ
export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    paddingTop:0.2,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'dark' ? 0.2 : 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.mode === 'dark' ? '#39393D' : '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  '@media (max-width: 600px)': { // スマホサイズ
    width: 50,
    height: 26,
    '& .MuiSwitch-thumb': {
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        transform: `translateX(${22}px)`,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
    },
  },
  '@media (min-width: 601px)': { // スマホ以外のサイズ
    width: 50,
    height: 26,
    '& .MuiSwitch-thumb': {
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        transform: `translateX(${22}px)`,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
    },
  },
}));