import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import LoginPage from './views/LoginPage/LoginPage.jsx';
import SignUpPage from './views/LoginPage/SignUpPage.jsx';
import ConfirmPage from './views/LoginPage/ConfirmPage.jsx';
import ResendConfirmPage from './ResendConfirmPage.jsx';
import CompletePage from './views/LoginPage/CompletePage.jsx';
import AccountTopPage from './views/AccountPage/AccountTopPage.jsx';
import OptoinPage from './OptoinPage.jsx';
import ErrorPage from './ErrorPage.jsx';
import MailEditPage from './views/AccountPage/MailEditPage.jsx';
import PasswordEditPage from './views/AccountPage/PasswordEditPage.jsx';
import EditInfoPage from './views/AccountPage/EditInfoPage.jsx';
import DeletePage from './views/AccountPage/DeletePage.jsx';
import RegistInfoPage from './views/LoginPage/RegistInfoPage.jsx';
import XIDAliginmentPage from './views/AccountPage/XIDAliginmentPage.jsx';


function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/regist" element={<RegistInfoPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/confirm" element={<ConfirmPage />} />
      <Route path="/resendconfirm" element={<ResendConfirmPage />} />
      <Route path="/complete" element={<CompletePage />} />
      <Route path="/account" element={<AccountTopPage />} />
      <Route path="/optin" element={<OptoinPage />} />
      <Route path="/editmail" element={<MailEditPage />} />
      <Route path="/editpassword" element={<PasswordEditPage />} />
      <Route path="/editinfo" element={<EditInfoPage />} />
      <Route path="/xidaliginment" element={<XIDAliginmentPage />} />
      <Route path="/delete" element={<DeletePage />} />
    </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;