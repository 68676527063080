//emailチェック
export const isValidEmail = (value) => {
    if (!value) return false;
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegExp.test(value);
};
//パスワードチェック（大文字・小文字・記号の10文字以上）
export const isValidPassword = (value) => {
    const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$_%^&*()])[\w!@#$%^&*()]{10,}$/;
    return value && passwordRegExp.test(value);
};
//パスワード＝＝パスワード再入力チェック
export const isConfirmPassword = (text, password) => {
    return text === password;
};