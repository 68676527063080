import React from 'react';
import AppRoutes from './Route.jsx';

function App() {
  return (
      <div className="App">
        <main>
          <AppRoutes />
        </main>
      </div>
  );
}

export default App;