import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import './LoginPage.css'; // スタイル用のCSSファイルを作成する
import { inputStyles } from '../../componets/TextField.jsx'
import ProgressSteps from '../../componets/ProgressSteps.jsx';
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import {isValidEmail, isValidPassword, isConfirmPassword} from '../../utils/validation.js'

//画像のインポート
import backgroundImage from '../../assets/backgroun-image.png';
import sendaiImage from '../../assets/sendai.png';

function LoginPage(){
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordCheckError, setPasswordCheckError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);

  const navigate = useNavigate();

  const navigateToNextScreen = (user_id) => {
    console.log(user_id);
    navigate('/confirm' , { state: { email: mail, id:user_id } } );
  };

  const signup = async () => {
    if (mail && password && passwordCheck) {
      setIsLoading(true);
      try {
        const response = await fetch('https://l0be5l7l79.execute-api.ap-northeast-1.amazonaws.com/default/signUpFunction', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            email:mail,
            password:password
          }),
          credentials:'include'
        });
      
        if (response.ok) {
          const responseData = await response.json();
          console.log('User registration successful', responseData);
          navigateToNextScreen(responseData.response_id);
        } else {
          const errorData = await response.json();
          console.error('Failed to register user:', errorData);
          setErrorMessage(errorData.message);
          setErrorCheck(true);
        }
      } catch (error) {
        console.error('Error during sign-in:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      const translatedMessage = 'ユーザ名またはパスワード・パスワード再入力が入力されていません';
      setErrorMessage(translatedMessage);
      setErrorCheck(true);
    }
  };

  useEffect(() => {
    if(passwordCheck.length > 0){
      if (emailError || passwordError || passwordCheckError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
    console.log(emailError, passwordError, passwordCheckError, isButtonDisabled)
  }, [emailError, passwordError, passwordCheckError]);

  //入力されたメールのチェック
  useEffect(() => {
    if(mail.length > 0){
      setEmailError(!isValidEmail(mail));
    }
  }, [mail]);
  //入力されたパスワードのチェック
  useEffect(() => {
    if(password.length > 0){
      setPasswordError(!isValidPassword(password));
    }
  }, [password]);
  //入力されたパスワード確認の情報のチェック
  useEffect(() => {
    if(passwordCheck.length > 0){
      setPasswordCheckError(!isConfirmPassword(passwordCheck, password));
    }
  }, [passwordCheck, password]);

  return (
    <div className="login-page">
      <img src={backgroundImage} alt="Background" className="background-image" />
      <img src={sendaiImage} alt="Sendai" className="logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={0}/>
      <div className="login-container">
        <div className="form-container">
          {errorCheck && 
            <div className='error-massage'>
              {errorMessage}
            </div>
          }
          <label htmlFor="email" className="email">メールアドレス</label>
          <TextField
            type="email"
            id="email"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            placeholder="例：sendai-portal@sample.com"
            fullWidth
            error={emailError}
            slotProps={{
              input: {
                sx: inputStyles
              },
            }}
          />
          {emailError && (
              <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                無効なメールアドレスです
              </FormHelperText>
            )}
          <label htmlFor="password">パスワード</label>
          <TextField
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            error={passwordError}
            slotProps={{
              input: {
                sx: inputStyles
              },
            }}
          />
          {passwordError && (
              <FormHelperText error style={{whiteSpace: 'nowrap' }}>
                10文字以上で、大文字、小文字、数字、記号
              </FormHelperText>
            )}
          <label htmlFor="passwordCheck">パスワード再入力</label>
          <TextField
            type="password"
            id="passwordCheck"
            value={passwordCheck}
            onChange={(e) => setPasswordCheck(e.target.value)}
            fullWidth
            error={passwordCheckError}
            helperText={passwordCheckError ? 'パスワードが一致しません。' : ''}
            slotProps={{
              input: {
                sx: inputStyles
              },
            }}
          />
          <br/>
          <Button className="loginButton" onClick={signup} disabled={isButtonDisabled}>
            次へ
          </Button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>登録中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default LoginPage;