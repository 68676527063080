//クライアントによるリクエストログイン（アプリに戻すためのログイン）
import { requestLogin } from './RequestLogin.js';

//オプトイン更新
export const setOptoin = async (userInfo_flag) => {

  try {
    const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        userAttributes: [
          {
              Name: "custom:optin",
              Value: "1"
          }
        ]
      }),
      credentials:'include'
    });
  
    if (response.ok) {
      const responseData = await response.json();
      console.log('User registration successful', responseData);

      if(userInfo_flag){
        requestLogin();
      }else if(!userInfo_flag){
        return true;
      }
    } else {
      const errorData = await response.json();
      console.error('Failed to register user:', errorData);
      return false;
    }

  } catch (error) {
    console.error('Error during registration:', error);

    return false;
  }
};

//オプトインの確認
export const checkOptoin = async (codeParam) => {
  try{
    const response = await fetch('https://dev-api.sendai-portal.jp/signInFunction', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${codeParam}`
      },
      body: JSON.stringify({
        redirect_uri:'https://dev-login.sendai-portal.jp/',
        pattern:'optin_check'
      }),
      credentials: 'include'
    });

    return response;

  }catch(error){
    console.error('オプトインの取得に失敗しました。:', error);
    return error;
  }
}