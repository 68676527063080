function ErrorPage(){
  
  return (
    <div>
      <label style={{fontSize: '16px', fontWeight: '700', marginTop:'10px', marginBottom:'20px', display: 'block'}}>登録されたユーザがありません</label>
      <footer>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ErrorPage;