import { generateCodeVerifier, generateCodeChallenge } from './PKCEService.js';

//SENDAIポータルのログイン
export const sendaiPortalLogin = () => {

    //コードチャレンジ作成
    const verifier = generateCodeVerifier();
    const challenge = generateCodeChallenge(verifier);

    // セッションストレージに保存
    sessionStorage.setItem('code_verifier', verifier);

    const clientId = 'l5bh1ba8ctbooblv82u6d3922';
    const responseType = 'code';
    const scope = 'openid profile email aws.cognito.signin.user.admin';
    //const redirectUri = 'https://staging.doxdqdo65v8mx.amplifyapp.com/';
    const redirectUri = 'https://dev-login.sendai-portal.jp/';
    const identityProvider = 'sendai-portal';
    const state = '0123456789';
    const nonce = 'idtokendesuyonekorewa';
    // const codeChallenge = challenge; //クライアント側が指定
    // const codeChallengeMethod = 'S256';

    const authUrl = `https://sendai-portal-auth-dev.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?` +
      `client_id=${clientId}&` +
      `response_type=${responseType}&` +
      `scope=${encodeURIComponent(scope)}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `identity_provider=${identityProvider}&` +
      `state=${state}&` +
      `nonce=${nonce}&`
      // `code_challenge=${codeChallenge}&` +
      // `code_challenge_method=${codeChallengeMethod}`;

    //Auth0のログイン画面を表示
    window.location.href = authUrl;
 };
