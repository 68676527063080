import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import './views/LoginPage/LoginPage.css';  // スタイル用のCSSファイルを作成する
import ProgressSteps from './componets/ProgressSteps.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { inputStyles, inputStylesMail  } from './componets/TextField.jsx'

//画像のインポート
import backgroundImage from './assets/backgroun-image.png';
import sendaiImage from './assets/sendai.png';

function ResendConfirmPage(){
  const location = useLocation();
  const { email } = location.state || {};
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);

  const navigate = useNavigate();
  const navigateToCompleteScreen = () => {
    navigate('/complete');
  };

  //emailが空だったらログインホームに戻す（/resendconfirmに直接アクセスされないように）
  useEffect(() => {
    if (!email) {
      navigate('/');
    }
  }, [email, navigate]);

  //コード入力を送信
  const signUpConfirm = async () => {
    if (code) {
      setIsLoading(true);
      try {
        const response = await fetch('https://c9l11b5md4.execute-api.ap-northeast-1.amazonaws.com/default/signUpConfirmFunction', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({
            email: email,
            code: code,
          }),
        });
        //ユーザ登録コード発行成功
        if (response.status === 200) {
          //alert('Successfully.');
          console.log('Response from Lambda:', response.text());
          navigateToCompleteScreen();
        } else {
          const responseBody = await response.json();
          const errorMessage = responseBody.message;
          console.error('Failed to call Lambda function:', response.status);
          console.error('Response body:', errorMessage);

          // Translate the error message
          const translatedMessage = '不明なエラーが発生しました';
          setErrorMessage(translatedMessage);
          setErrorCheck(true);
          console.error('Translated error message:', translatedMessage);
        }
      } catch (error) {
        console.error('Error during sign-in:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      const translatedMessage = '確認コードが入力されていません';
      setErrorMessage(translatedMessage);
      setErrorCheck(true);
    }
  };  

  return (
    <div className="login-page">
      <img src={backgroundImage} alt="Background" className="background-image" />
      <img src={sendaiImage} alt="Sendai" className="logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={1}/>
      <div className="login-container">
        <div className="form-container">
          {errorCheck && 
            <div className='error-massage'>
              {errorMessage}
            </div>
          }
          <div style={{ width: '100%' }}>
              <label style={{ display: 'block', textAlign: 'start', marginTop: '15px', marginBottom: '0px' }}>下記メールアドレスに確認コードを再送信しました。送信された確認コードを入力してください。</label>
          </div>
          <label htmlFor="email" className="email">送信先メールアドレス</label>
          <TextField
              type="email"
              id="email"
              value={email}
              onChange={() => {}}
              fullWidth
              disabled
              slotProps={{
                input: {
                    sx: inputStylesMail
                },
               }}
          />
          <label htmlFor="password">認証コード</label>
          <TextField
              type="number"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
              slotProps={{
                input: {
                    sx: inputStyles
                },
              }}
          />
          <Link href="#" className='passforget' onClick={() => { /* パスワードを忘れた時の処理 */ }}>
              確認コードが届かない場合
          </Link>
          <Button className="loginButton" onClick={signUpConfirm}>次へ</Button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <h2>ログイン中です。しばらくお待ちください。</h2>
          <div className="loader"></div>
        </div>
      )}
      <footer>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ResendConfirmPage;